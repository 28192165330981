<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />

    <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
      :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
            <div class="d-flex align-items-center justify-content-start">
              <b-button variant="primary" @click="openModal">
                <span class="text-nowrap lableTitle">Add New Client</span>
                <!-- style="font-size:15px;font-weight:500;font-family: 'Public Sans', sans-serif;" -->
              </b-button>
            </div>
          </b-col>

          <b-col md="6" class="d-flex mt-1 ml-100">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px"
                  placeholder="Search..."
                  :options="getOptions(searchTerm.storename)"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  tbody-tr-class="custom-table-row"
                  :options="searchTermOptions"
                  @input="handleSearchType()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <div
        v-if="isLoading"
        style="height: 200px"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div> -->
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="tabledata"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        thead-class="head"
        @sort-changed="onSortChanged"
        >
        <!-- :sort-desc.sync="orderBy" -->
        <!-- :sort-by.sync="sortBy" -->
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(f_name)="data" >
          <div class="font-weight-bolder cursor-pointer w-100 d-flex align-items-center" @dblclick="openProfile(data.item)">
            <span class="mr-1">
              <b-avatar
                size="32"
                :src="data.item.profilephoto"
                :text="data.item.f_name.substring(0, 1)"
              />
            </span>
            {{ data.item.f_name }}
          </div>
        </template>
        <template #cell(trainer)="data">
          <div class="d-flex w-100 align-items-center" v-if="data.item.trainer">
            <div class="mr-1">
              <b-avatar
                size="32"
                :src="data.item.trainer.profilephoto"
                :text="data.item.trainer.f_name.substring(0, 1)"
              />
            </div>
            <div style="max-width:200px">
              <p class="m-0 lableTitle">
                {{
                  data.item && data.item.trainer ? data.item.trainer.f_name : ""
                }}
              </p>
              <small class="lableTitle" v-if="data.item.trainer">{{
                setspecialization(data.item.trainer.all_specialization)
              }}</small>
            </div>
          </div>
        </template>
        <template #cell(goal)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="lableTitle">
              {{ data.item.goal_name}}
              <!-- {{  }}
              {{
                data.item &&
                data.item.customer_goal &&
                data.item.customer_goal.length > 0
                  ? data.item.customer_goal[0].goal.name
                  : ""
              }} -->
              <!-- {{ 
                data.item.goal? data.item.goal.name:''
               }} -->
            </p>
          </div>
        </template>
        <template #cell(phone)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="lableTitle">{{ data.item.phone }}</p>
          </div>
        </template>
        <template #cell(progress)="data">
          <div class="d-flex align-items-center" style='width:150px'>
            <span class="lableTitle">{{ data.item.progress ? data.item.progress.toFixed(2):0  }}%</span>

            <b-progress
              :value="data.item.progress"
              class="w-100 ml-1"
              :variant="data.item.progress < 50 ? 'danger' : 'success'"
            ></b-progress>
          </div>
        </template>
        <template #cell(Actions)="data">
          <div class="d-flex">
            <!-- <feather-icon
              icon="MailIcon"
              size="22"
              class="cursor-pointer mr-1"
            /> -->
            <feather-icon
              icon="EditIcon"
              size="22"
              class="cursor-pointer mr-1"
              @click="handleEditForm(data.item)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="22"
              @click="removeRow(data.item.id)"
              class="cursor-pointer mr-1"
            />
            <feather-icon
              icon="EyeIcon"
              size="22"
              @click="openProfile(data.item)"
              class="cursor-pointer mr-1"
            />
          </div>
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
          <!-- <div>
            <button
              @click="goToPage(paginationValue - 1)"
              :disabled="paginationValue === 1"
            >
              <feather-icon icon="ChevronLeftIcon" size="45" />
            </button>
            <button
              @click="goToPage(paginationValue + 1)"
              :disabled="paginationValue * per_page >= paginationTotal"
            >
              <feather-icon icon="ChevronRightIcon" size="45" />
            </button>
          </div> -->
        </b-pagination>
      </div>
    </b-card>

    <validation-observer ref="simpleRules">
      <b-modal
        hide-footer
        no-close-on-backdrop
        v-model="AddNewUser"
        @close="resetModal"
        centered
        size="lg"
      >
        <b-form ref="myForm">
          <b-row>
            <b-col md="12" class="d-flex align-items-center flex-column">
              <h3
                class="mt-1 font-weight-bolder"
                style="font-size: 26px; font-family: 'Public Sans', sans-serif"
              >
                {{
                  ifEdit == true ? "Update Client Details" : "Add New Client"
                }}
              </h3>
              <p>Updating user details will receive a privacy audit.</p>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="mt-1 font-weight-bolder" for="h-first-name"
                  >Full Name</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Full Name"
                >
                  <b-form-input       
                    id="h-first-name"
                    v-model="fullname"
                    placeholder="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="mt-1 font-weight-bolder" for="h-gender"
                  >Gender</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Gender"
                >
                  <v-select
                    id="h-gender"
                    v-model="gender"
                    label="name"
                    :options="getOptions('gender')"
                    placeholder="Select gender"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-goal">Goal</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Goal"
                >
                  <v-select
                    id="h-goal"
                    v-model="goal"
                    label="name"
                    :options="getOptions('goal')"
                    placeholder="Select your goal"
                    @input="handleWeeklyGoal"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" :class="goal && goal.name!= 'Maintain Weight'?'':'d-none'">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-goal">Weekly Goal</label>
                  <v-select
                    id="h-goal"
                    v-model="weekly_goal"
                    label="name"
                    :options="goal && goal.name=='Gain Weight'? getOptions('gainWeightData') :getOptions('loseWeightData') "
                    placeholder="Select your weekly goal"
                  ></v-select>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-first-name"
                  >Create UserID</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="UserID"
                >
                  <b-form-input
                    v-model="UserID"
                    type="text"
                    autocomplete="new-text"
                    maxlength="20"
                    placeholder="UserID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-first-name"
                  >Birth Date</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Date of Birth"
                >
                <!-- <flat-pickr
                    v-model="dob"
                    style="background-color: transparent"
                    class="form-control "
                    placeholder="DD/MM/YYYY"
                    :config='{
                      dateFormat: "m/d/Y",
                      maxDate: "today"
                    }'
                  /> -->
                   <datepicker v-model="dob" 
                    inputClass="form-control bg-transparent"
                    format="dd/MM/yyyy"
                    :disabled-dates="disabledDates"
                    ></datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-height"
                  >Height</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Height"
                >
                  <b-form-input
                    id="h-height"
                    maxlength="3"
                    v-model="height"
                    placeholder="158 Cm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1">Current Weight</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Current Weight"
                >
                  <b-form-input
                    v-model="weight"
                    placeholder="60Kg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-weight"
                  >Goal Weight</label
                >
                <validation-provider
                  #default="{ errors }"
                  name="Goal Weight"
                >
                  <b-form-input
                    id="h-weight"
                    v-model="goal_weight"
                    placeholder="60Kg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-email"
                  >Email</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required|email"
                  name="Email"
                >
                  <b-form-input
                    id="h-email"
                    maxlength="50"
                    v-model="email"
                    placeholder="Email"
                    type="email"
                  />
                  <small
                    :class="con_email!== email?'':'d-none'"
                    class="text-danger"
                    >Email and Confirm email must be
                    same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-con_email"
                  >Confirm Email</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required|email"
                  name="Confirm Email"
                >
                  <b-form-input
                    id="h-con_email"
                    v-model="con_email"
                    placeholder="Email"
                    type="email"
                    maxlength="50"
                  />
                  <small
                  :class="con_email!== email?'':'d-none'"
                    class="text-danger"
                    >Email and Confirm email must be
                    same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-con_contact"
                  >Contact</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                  name="Contact"
                >
                  <b-form-input
                    id="h-con_contact"
                    maxlength="15"
                    v-model="contact"
                    placeholder="Contact No."
                  />
                  <small
                  :class="con_contact!== contact?'':'d-none'"

                  class="text-danger"
                  >Contact no. and Confirm contact no. must be
                  same.</small
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-contact"
                  >Confirm Contact</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                  name="Confirm Contact"
                >
                  <b-form-input
                    id="h-contact"
                    maxlength="15"
                    v-model="con_contact"
                    placeholder="Contact No."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                  :class="con_contact!== contact?'':'d-none'"

                    class="text-danger"
                    >Contact no. and Confirm contact no. must be
                    same.</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" 
            :class="ifEdit?'d-none':''"
            >
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-pass"
                  >Password</label
                >
                <validation-provider
                  #default="{ errors }"
                  :rules="ifEdit ? '':'required|password'"
                  name="password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="h-pass"
                      maxlength="20"
                      v-model="password"
                      autocomplete="new-password"
                      :type="passwordIcon"
                      name="Password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="passwordMismatch" class="text-danger"
                    >Password and Confirm Password must be same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" :class="ifEdit?'d-none':''">
              <b-form-group>
                <label class="mt-1 font-weight-bolder" for="h-pass-con"
                  >Confirm Password</label
                >
                <validation-provider
                  #default="{ errors }"
                  :rules="ifEdit ? '':'required|password'"

                  name="Confirm"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="h-pass-con"
                      maxlength="20"
                      autocomplete="new-password"
                      v-model="confirm_password"
                      :type="confirmpasswordIcon"
                      name="Confirm Password"
                      placeholder="Confirm Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="ConfirmIcon"
                        @click="toggleConfirmPassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="passwordMismatch" class="text-danger"
                    >Password and Confirm Password must be same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="mt-1 font-weight-bolder" for="h-languag"
                  >Language</label
                >
                <validation-provider #default="{ errors }" name="Language">
                  <b-form-input
                    id="h-language"
                    :value="language"
                    disabled
                    label="name"
                    placeholder="English"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-country"
                  >Country</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Country"
                >
                  <v-select
                    v-model="country"
                    label="name"
                    :options="getOptions('country')"
                    placeholder="Select Country"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bold mt-1"
                  >Medical Injuries</label
                >
                  <b-form-textarea
                    v-model="injuries"
                    placeholder="Medical Injuries"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bold mt-1"
                  >Perpose of Workout</label
                >
                  <b-form-textarea
                    v-model="purpose_of_workout"
                    placeholder="Perpose Of Workout"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex flex-column align-items-center">
              <h3
                style="
                  font-weight: 600;
                  margin-top: 10px;
                  font-size: 26px;
                  font-family: 'Public Sans', sans-serif;
                "
              >
                Membership Details
              </h3>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-plan"
                  >Select Plans</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Plan"
                >
                  <v-select
                    id="h-plan"
                    v-model="plans"
                    label="name"
                    :options="getOptions('plan')"
                    placeholder="Select Your Plan"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col md="12" class="d-flex justify-content-center mt-0">
              <b-button
                @click.prevent="
                  ifEdit ? handleSubmitForm(tabledata.id) : handleNewPage()
                "
                variant="primary"
                class="mr-1 lableTitle"
              >
                {{ ifEdit == true ? "Update" : "Submit" }}
              </b-button>
              <b-button
                type="reset"
                variant="primary"
                class="labelTitle"
                @click="Cancel"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
    <b-modal
      hide-footer
      v-model="assignTrainer"
      no-close-on-backdrop
      centered
      size="md"
    >
      <b-row class="p-2">
        <b-col md="12" class="d-flex align-items-center flex-column">
          <h2 style="font-size: 26px" class="font-weight-bolder lableTitle">
            Clients assigned to trainers
          </h2>
          <p class="labelTitle">
            "Assign to trainers with our gym management software."
          </p>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label class="font-weight-bolder mt-1" for="h-trainer"
              >Select Trainer</label
            >
            <b-form-input
              id="h-trainer"
              v-model="newSearch"
              @input="handleTrainerSearch()"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <h2 class="font-weight-bolder text-dark mr-1">
            {{ totalTairner }} Trainers
          </h2>
        </b-col>
        <b-col
          md="12"
          
          class="mt-2 trainerList"
        >

        <div
          v-if="isLoadingTrainer"
          style="height: 200px"
          class="d-flex justify-content-center align-items-center"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
          <div class="w-100 p-1" v-else v-for="(item,index) in allTrainer" :key="index">
            <div class="d-flex w-100 align-items-center">
              <div class="mr-1">
                <b-avatar size="32" :src="item.profilephoto" />
              </div>
              <div class="w-100">
                <h5 class="m-0 lableTitle">
                  {{ item.f_name }}
                </h5>
                <small>{{
                  setspecialization(item.all_specialization)
                }}</small>
              </div>
              <div class="d-flex w-100 align-items-center justify-content-end">
                <b-button variant="primary" :disabled='submiting' @click="handleSubmitForm(item.id)">
                  <span>Select</span>
                </b-button>
              </div>
            </div>
          </div>

        </b-col>
      </b-row>
      <div class='d-flex justify-content-end'>
        <b-button @click='handleBackFromTrainer' variant="primary">Back</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormSelect,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@/component/axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import Datepicker from 'vuejs-datepicker';


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import { params } from 'vee-validate/dist/types/rules/alpha';

export default {
  components: {
    Datepicker,
    BFormSelect,
    BInputGroup,
    BCard,
    BRow,
    moment,
    required,
    BInputGroupAppend,
    BCol,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    required,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    BSpinner,
    vSelect,
    BForm,
    BFormTextarea
  },
  data() {
    return {
      moment,
      columnFilters: {},
      tableColumns: [
        {
          key: "id",
          label: "NO",
        },
        { key: "f_name", label: "Client", sortable: true },
        {
          key: "goal",
          label: "GOAL",
          type: "dropdown",
          displayName: "name",
        },
        {
          key: "trainer",
          label: "Trainer",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
          
        },
        {
          key: "phone",
          label: "Contact",
          sortable: true,
          
        },
        // { key: 'balance', sortable: true },
        { key: "Actions", label: "Actions" },
      ],
      searchTerm: { name: "Client", value: "customer" },
      newTerm: { name: "Trainer", value: "trainer" },
      newSearch: "",
      search: "",
      fullname: "",
      height: "",
      tabledata: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      weight: "",
      weekly_goal:'',
      goal_weight:'',
      isLoading: false,
      email: "",
      con_email: "",
      contact: "",
      con_contact: "",
      pincode: "",
      totalTairner: '',
      language: "English",
      password: "",
      confirm_password: "",
      value: 60,
      // orderBy: true,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      passwordMismatch: false,
      AddNewUser: false,
      gender: "",
      goal: "",
      goalSearch: "",
      loader: true,
      allTrainer: [],
      goalOption: [],
      passwordIcon: "password",
      confirmpasswordIcon: "password",
      genderOption: [],
      country: "",
      contryOption: [],
      plans: "",
      originalDate:moment().format("DD/MM/YYYY"),
      plansOption: [],
      ifEdit: false,
      id: 0,
      // UserID: "",
      isLoadingTrainer: false,
      assignTrainer: false,
      submiting:false,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      dob:'',
      disabledDates:{
        from: '',
      },
      purpose_of_workout:'',
      injuries:'',
      params: {},
      searchTermOptions: [
        { name: "Client", value: "customer" },
        {
          name: "Goal",
          value: "goal",
          type: "dropdown",
          displayName: "name",
          storename: "goal",
        },
        {
          name: "Trainer",
          value: "trainer",
          type: "dropdown",
          displayName: "f_name",
          storename: "trainers",
        },
      ],
      searchOptions: [],
      storeTainer:[]
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    setspecialization(){
      return(data)=>{
        if(data){

          let arr = data.map((item)=>{
            return item.specialization ? item.specialization.name :''
          })
          
          return arr.filter(item=> item ).join(', ')
        }
      return ''
      }
    }
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
    // '$store.state.app.trainers':{
    //   handler:'getallTrainer',
    //   immediate:true
    // }
  },
  mounted() {
    this.getClientData();
    this.getallTrainer();
    let year = new Date().getFullYear() -10
    this.disabledDates.from =new Date(new Date().setFullYear(year));
  },
  methods: {
    handleWeeklyGoal(){
      this.weekly_goal =''
    },
    handleBackFromTrainer(){
      this.AddNewUser=true;
      this.assignTrainer=false
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getClientData();
    },
    handleSearchType() {
      this.search = "";
      this.getClientData();
    },
    // filterTable: _.debounce(function () {
    //   this.params = {};
    //   for (let key in this.columnFilters) {
    //     if (
    //       this.columnFilters[key] &&
    //       typeof this.columnFilters[key] == "object"
    //     ) {
    //       this.params[key] = this.columnFilters[key]
    //         .map((inner) => inner.id)
    //         .join(",");
    //     } else {
    //       this.params[key] = this.columnFilters[key];
    //     }
    //   }
    //   this.params.page = this.paginationValue;
    //   this.getClientData();
    // }, 1000),

    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getClientData();
      }
    },
    resetModal() {
      this.AddNewUser = false;
      this.ifEdit = false;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.goal_weight='';
      this.con_email = "";
      this.email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.password = "";
      this.confirm_password = "";
      this.id = "";
      // this.UserID = "";
      this.weekly_goal=''
      this.purpose_of_workout=''
      this.injuries=''
      this.dob = moment().subtract(16,'year')._d
    },
    changePagination() {
      this.getClientData();
    },
    changeSearch() {
      if (this.search) {
        this.getClientData();
      }
    },
    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    async handleEditForm(item) {
      this.openModal()
      this.id = item.id;
      this.ifEdit = true;

      await axios({
        method: "GET",
        url: `${this.baseApi}customer/show/${item.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          let data = res.data.data.user;
          this.fullname = data.f_name;
          this.gender = data.gender;
          this.goal = data.customer_goal ? data.customer_goal.goal : "";
          this.height = data.height;
          this.weight =data.customer_goal ? data.customer_goal.current_weight : "";
          this.goal_weight =data.customer_goal ? data.customer_goal.goal_weight : "";
          this.email = data.email;
          this.con_email = data.email;
          this.contact = data.phone;
          this.con_contact = data.phone;
          this.language = "English";
          this.country = data.country;
          this.plans = res && res.data && res.data.data && res.data.data.customerPlan && res.data.data.customerPlan.plans ? res.data.data.customerPlan.plans : '' ;
          this.password = "";
          this.confirm_password = "";
          this.id = data.id;
          // this.UserID = data.username;
          this.purpose_of_workout=data.purpose_of_workout;
          this.injuries=data.injuries;
          this.weekly_goal = data.customer_goal ? (data.customer_goal.weeklygainweight??data.customer_goal.weeklyloseweight):''; 
          this.dob = data.dob && data.dob !=='0000-00-00' ? moment(data.dob)._d:''
        })
        .catch((error) => {
          console.error("Error update user:", error);
        });
    },
    async getallTrainer() {
      this.isLoadingTrainer = true;
      let params={}
      if(this.newSearch){
        params.search = this.newSearch
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-all-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((json) => {
          // this.storeTainer = this.$store.state.app.trainers;
            this.allTrainer = json.data.data
            this.totalTairner = this.allTrainer.length;


          // if(this.newSearch){
          //   this.allTrainer = this.storeTainer.filter((item)=>{
          //     let regex = new RegExp(this.newSearch, 'i');
          //     return regex.test(item.f_name)
          //   })
          // }
          // else{
          //   this.allTrainer = this.storeTainer.slice()
          // }

        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.isLoadingTrainer = false;
        });
    },
    async getClientData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/all`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.tabledata = json?.data.data.data;
          this.paginationTotal = json?.data.data.total;
          this.paginationStart = json?.data.data.from;
          this.paginationEnd = json?.data.data.to;
          this.isLoading = false;

          this.tabledata.map((item)=>{
            item.goal_name = item.customer_goal&& item.customer_goal.goal?item.customer_goal.goal.name:'' 
          })

        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error, "error");
        });
    },

    handleSearch: _.debounce(function () {
      this.getClientData();
      // this.getallTrainer();
    }, 1000),
    handleTrainerSearch: _.debounce(function () {
      this.getallTrainer();
    }, 1000),

    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}customer/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.tabledata.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.tabledata.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getClientData();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/clientmanagement/${userId}` });
    },
    openModal() {
      this.ifEdit = false;
      this.AddNewUser = true;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.con_email = "";
      this.email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "English";
      this.goal_weight=''
      this.country = "";
      this.plans = "";
      this.password = "";
      this.confirm_password = "";
      this.id = 0;
      // this.UserID = "";
      this.weekly_goal=''
      this.purpose_of_workout=''
      this.injuries=''
      this.dob = moment().subtract(16,'year')._d
    },
    Cancel() {
      this.AddNewUser = false;
      this.resetModal();
    },
    handleNewPage() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success && !this.passwordMismatch && this.email == this.con_email && this.contact == this.con_contact) {
          if (this.AddNewUser) {
            this.AddNewUser = false;
          }
          this.assignTrainer = true;
          this.search = "";
        }
        else{
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async handleSubmitForm(id) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.handleForm(id);
          this.getClientData();
        }
      });
    },
    async handleForm(id) {
      let endDate;

        switch (this.plans.id) {
          case 1:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(1, "month")
              .format("YYYY-MM-DD");
            break;
          case 2:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(3, "months")
              .format("YYYY-MM-DD");
            break;
          case 3:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(6, "months")
              .format("YYYY-MM-DD");
            break;
          case 4:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(9, "months")
              .format("YYYY-MM-DD");
            break;
          case 5:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(12, "months")
              .format("YYYY-MM-DD");
            break;
          default:
            // Handle other cases or set a default behavior
            break;
        }
        this.submiting=true
      const customerData = {
        f_name: this.fullname,
        gender: this.gender.id,
        goal_id: this.goal.id,
        height: this.height,
        current_weight: this.weight,
        email: this.email,
        phone: this.contact,
        language: "English",
        country: this.country.id,
        plan_id: this.plans.id,
        password: this.password,
        confirm_password: this.confirm_password,
        trainer_id: id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        goal_weight:this.goal_weight,
        // username: this.UserID,
        dob:moment(this.dob).format('yyyy-MM-DD'),
        purpose_of_workout:this.purpose_of_workout,
        injuries:this.injuries
      };

      if(this.goal && this.goal.name =='Gain Weight'){
        customerData.gain_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }
      else if(this.goal && this.goal.name =='Lose weight'){
        customerData.lose_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }

      const requestOptions = {
        method: `${this.ifEdit ? "put" : "post"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: this.ifEdit
          ? `${this.baseApi}customer/update/${this.id}`
          : `${this.baseApi}customer/add`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then((response) => {
          if (response.data.success === false) {
            // Display warning message for failure
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: response.data.message
                ? `${response.data.message}`
                : "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
              
            });
            this.AddNewUser = true;
            this.assignTrainer = false;
          } else if (response.data.success === true) {
            // Display success message for success
            this.$swal({
              title: "Submitted",
              text: this.ifEdit
                ? "User Updated Successfully"
                : "User Created Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.AddNewUser = false;
            this.assignTrainer = false;
          }
          this.$store.dispatch("app/getAllCustomer");
          // this.resetModal();
          this.$forceUpdate();
        })
        .catch((error) => {
          this.AddNewUser = true;
          this.assignTrainer = false;
          let string = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            string = error.response.data.message;
          }
          if (error.response.data.message) {
            string = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            text: string,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error adding customer:", error);
        })
        .finally(()=>{
          this.submiting=false
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.head {
  width: 100px;
}
.lableTitle {
  font-size: 15px;
  font-weight: 500;
  font-family: "Public Sans", sans-serif;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.trainerList{
  max-height: 500px;
  overflow: auto;
}
</style>